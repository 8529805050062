import { global } from ":global";
import { _Firebase } from "./_model";

export async function addQuestionAnswer(props: _Firebase.AddAnswerProps) {
  const id = String(props.entity.id);
  const entity = props.entity ?? ({} as typeof props.entity);
  if (!entity.question || entity.question === "") {
    entity.question = "UNKNOWN QUESTION!!";
  }
  if (!entity.answers) {
    entity.answers = [];
  }

  entity.answers.push(props.answer);
  return global.fb.rtdb.set({
    path: `/open-question/${id}/answers`,
    value: entity.answers,
  });
}
